import React, { createContext, useContext, useEffect, useState } from "react";

type ResponsiveContext = {
  isTouchDevice: boolean;
};

const responsiveContext = createContext<ResponsiveContext>({
  isTouchDevice: false,
});

type ResponsiveProviderProps = {
  children: React.ReactNode;
};

export const ResponsiveProvider = ({ children }: ResponsiveProviderProps) => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  useEffect(() => {
    setIsTouchDevice(
      !!(
        "ontouchstart" in global ||
        (global.navigator && global.navigator.maxTouchPoints)
      )
    );
  }, []);

  return (
    <responsiveContext.Provider value={{ isTouchDevice }}>
      {children}
    </responsiveContext.Provider>
  );
};

export const useResponsive = () => useContext(responsiveContext);
