import useStorage, { StorageType, ValidationFn } from "./use-storage";

const useLocalStorage = (
  key: string,
  initialValue: any,
  validationFn?: ValidationFn
) => {
  return useStorage(StorageType.LOCAL, key, initialValue, validationFn);
};

export default useLocalStorage;
