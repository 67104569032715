import * as React from "react";

// .reduce is kind of complex. It takes an array and returns anything, so you have to tell the compiler what reduce is expected to return. In this case, we want reduce to return a ReactChildArray. So we return it like `return childrenArray.reduce<ReactChildArray>(`
// In other words, .reduce is a generic function that takes a single generic parameter, what shows up between <>

type ReactChildArray = ReturnType<typeof React.Children.toArray>;

function flattenChildren(children: React.ReactNode): ReactChildArray {
  const childrenArray = React.Children.toArray(children);

  return childrenArray.reduce<ReactChildArray>(
    (flatChildren: ReactChildArray, child) => {
      if ((child as React.ReactElement<any>).type === React.Fragment) {
        return flatChildren.concat(
          flattenChildren((child as React.ReactElement<any>).props.children)
        );
      }
      flatChildren.push(child);
      return flatChildren;
    },
    []
  );
}
export default flattenChildren;
