import React, { createContext } from "react";
import styled, { css } from "styled-components";
import { animated } from "react-spring";

import Stack from "./stack";
import { useResponsive } from "./responsive-provider";
import useItemSize, { BoundingBoxSizeProps } from "../hooks/use-item-size";

type PageSizeContextProperties = BoundingBoxSizeProps;
export const pageSizeContext = createContext<PageSizeContextProperties>({
  width: 1,
  height: 1,
});

type ContainerProps = {
  $noTopPadding?: boolean;
};
const Container = styled.section<ContainerProps>`
  ${(props) => css`
    box-sizing: border-box;

    /* Need for very large width screens when using stretched heights */
    margin: 0 auto;
    padding: ${props.$noTopPadding ? 0 : props.theme.HeaderHeight}px 0
      ${props.theme.PagePadding}px;
    min-height: 100%;

    /* Always define this in case an item inside is flexed */
    display: flex;
    flex-direction: column;
  `}
`;

type LaneProps = {
  $isTouchDevice?: boolean;
};

// Rules:
// - Never define top and bottom padding inside of this component - it should only deal with the layout of the page
// - Never wrap Page.Hero inside of Page.Lane because it messes up mobile styles (the background needs to stretch beyond Content)
const Lane = styled.div<LaneProps>`
  ${(props) => css`
    ${props.theme.MaxWidth()}
    position: relative;

    ${!props.$isTouchDevice
      ? css`
          padding: 0 ${props.theme.OutsidePagePadding}px;
        `
      : css`
          padding: 0 ${props.theme.Padding}px;
        `}
  `}
`;

type TitleProps = {
  $bigFont?: boolean;
  $leftAlign?: boolean;
};
const Title = styled(animated.h1)<TitleProps>`
  ${(props) => css`
    ${props.theme.FontHeader()}
    ${props.theme.H1FontSize()}
    ${props.$bigFont && `font-size: 50px;`}

    /* Do not add margin-bottom in the case there is a subtitle below the title */
    margin: 0;
    padding: 0;

    /* Anchor pages need left aligned titles */
    text-align: ${props.$leftAlign ? "left" : "center"};
  `}
`;

const Subtitle = styled.div<TitleProps>`
  /* SecondaryColor looks best on Discover page */
  ${(props) => css`
    color: ${props.theme.Color.SecondaryColor};
    font-size: 18px;
    margin: 0;

    /* Anchor pages need left aligned subtitles */
    text-align: ${props.$leftAlign ? "left" : "center"};
  `}
`;

const H2 = styled.h2`
  ${(props) => css`
    ${props.theme.FontHeader()}
    ${props.theme.H2FontSize()}

    /* do not add margin-bottom in the case there is a subtitle below the h2 */
    margin: 0;
    padding: 0;
  `}
`;

const P = styled.p`
  margin: 0;
  padding: 0;

  & + p {
    margin-top: ${(props) => props.theme.Padding * 2}px;
  }

  & + ul {
    margin-top: ${(props) => props.theme.Padding * 2}px;
  }
`;

type NoteProps = {
  $center?: boolean;
};
const Note = styled.p<NoteProps>`
  ${(props) => css`
    color: ${props.theme.Color.SecondaryColor};
    margin: 0;

    ${props.$center &&
    css`
      text-align: center;
    `}
  `}
`;

const AbsolutelyCentered = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${(props) => props.theme.ZIndex.Navigation};
`;

const ANGLE_HEIGHT = 150;
const BOTTOM_ANGLED_PADDING = (props) => props.theme.Padding;
const HeroBg = styled.div`
  ${(props) => css`
    background-image: linear-gradient(
      -6deg,
      ${props.theme.Color.HeroGradientPrimary} 55%,
      ${props.theme.Color.HeroGradientSecondary} 100%,
      ${props.theme.Color.HeroGradientSecondary} 100%
    );
    padding: ${props.theme.HeaderHeight}px 0 0;

    position: relative;
    top: 0;
    left: 0;
    width: 100%;

    &:after {
      background-color: ${props.theme.Color.HeroGradientPrimary};
      display: block;
      content: "";

      position: absolute;
      bottom: ${ANGLE_HEIGHT / -3}px;

      width: 100%;
      height: ${ANGLE_HEIGHT / 3}px;
      z-index: 0;
    }
  `}
`;

const HeroBgAngle = styled.div`
  ${(props) => css`
    background-color: ${props.theme.Color.HeroGradientPrimary};
    position: absolute;
    bottom: ${BOTTOM_ANGLED_PADDING(props) * -1}px;
    transform: skewY(-2deg) translateY(100%);
    transform-origin: top left;
    width: 100%;
    height: ${ANGLE_HEIGHT}px;
  `}
`;

// The z-index needs to set for Safari 14.0.3 Challenge pages. Otherwise, the HeroBg angled background does not display
const StackStyled = styled(Stack)`
  z-index: 0;
`;

type PageProps = {
  noTopPadding?: boolean;
  children?: JSX.Element | JSX.Element[];
  className?: string;
};

// This is for the width of the content on the site. It allows the page to have proper gutters and padding. No additional properties should be sent to PageLane
type PageLaneProps = {
  children: any;
  className?: string;
};
const PageLane = ({ children, className }: PageLaneProps) => {
  const { isTouchDevice } = useResponsive();
  const [size, ref] = useItemSize();

  return (
    <pageSizeContext.Provider value={size}>
      <Lane $isTouchDevice={isTouchDevice} className={className}>
        <div ref={ref}>{children}</div>
      </Lane>
    </pageSizeContext.Provider>
  );
};

const PageLaneAboveHero = styled(PageLane)`
  z-index: 1;
`;

type PageHeroTypes = {
  children?: React.ReactNode;
  className?: string;
};
const PageHero = ({ children, className }: PageHeroTypes) => {
  return (
    <HeroBg className={className}>
      <PageLaneAboveHero>{children}</PageLaneAboveHero>
      <HeroBgAngle />
    </HeroBg>
  );
};

const Page = ({ noTopPadding, className, children }: PageProps) => (
  <Container $noTopPadding={noTopPadding} className={className}>
    <StackStyled>{children}</StackStyled>
  </Container>
);

// This is for the width of the site
Page.Lane = PageLane;
Page.Title = Title;
Page.Hero = PageHero;
Page.Subtitle = Subtitle;
Page.H2 = H2;
Page.P = P;
Page.Note = Note;
Page.AbsolutelyCentered = AbsolutelyCentered;

export default Page;
