import { useRef, useEffect, useState, RefObject, useCallback } from "react";

export type BoundingBoxSizeProps = {
  height: number;
  width: number;
};
const defaultSize = { width: 1, height: 1 };

const useItemSize = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [boundingBoxSize, setBoundingBoxSize] = useState<BoundingBoxSizeProps>(
    defaultSize
  );

  const handleResize = useCallback(() => {
    const { width, height } = ref.current
      ? ref.current.getBoundingClientRect()
      : defaultSize;
    setBoundingBoxSize({ width, height });
  }, []);

  useEffect(() => {
    handleResize();
    /**
     * There is a race condition that occurs between component mounting and isTouchDevice.
     * This addresses that because isTouchDevice will trigger a rerender which will show a
     * diff in this dependency array and allow us to remeasure the element
     *
     * this is a hack, but its mostly fine
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return [boundingBoxSize, ref] as [
    BoundingBoxSizeProps,
    RefObject<HTMLDivElement>
  ];
};

export default useItemSize;
