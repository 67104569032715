import { NextRouter } from "next/router";
import { formEncoded } from "../http";

export const generateLoginRedirect = (router: NextRouter) => {
  let returnTo = router.pathname;
  if (Object.keys(router.query).length > 0) {
    returnTo += `?${formEncoded(router.query)}`;
  }
  return `/auth/login?returnTo=${encodeURIComponent(returnTo)}&immediate=true`;
};
