import { useMemo } from "react";

import useAuth from "@/hooks/use-auth";

import { makeClientApi } from "@/util/client-request";

const useClientApi = () => {
  const auth = useAuth();

  const clientApi = useMemo(() => makeClientApi(auth.refreshSession), [
    auth.refreshSession,
  ]);

  return clientApi;
};

export default useClientApi;
