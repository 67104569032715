import React, { createContext, useEffect, useCallback, useState } from "react";

import { makeClientApi } from "../client-request";
import useLocalStore from "../../hooks/use-local-storage";
import { useRefTrackingState } from "../../hooks/use-ref-state";

import { User, Session } from "@/types/auth";
import { useRouter } from "next/router";

type RefreshSessionParams = {
  force: boolean;
  delayUpdateUntilRoute?: string;
};

export type RefreshSessionFn = (params: RefreshSessionParams) => Promise<void>;

const NoOpRefreshSession: RefreshSessionFn = async (params) => {};

type AuthContext = {
  user?: User;
  refreshSession?: RefreshSessionFn;
};

export const authContext = createContext<AuthContext>({
  user: null,
  refreshSession: null,
});

const validateSession = (session: any): boolean =>
  session &&
  Object.keys(session).length > 0 &&
  session.expires &&
  session.expires > Date.now();

type AuthProviderProps = {
  children: React.ReactNode;
  initialSession: any;
};

type DelayedUpdateUntilRoute = {
  route: string;
  session: Session;
};
export const AuthProvider = ({
  children,
  initialSession,
}: AuthProviderProps) => {
  if (initialSession) {
    initialSession.expires = Date.now() + initialSession.revalidateAge * 1000;
  }

  const [
    delayedUpdateUntilRoute,
    setDelayedUpdateUntilRoute,
  ] = useState<DelayedUpdateUntilRoute | null>(null);

  const [session, setSession] = useLocalStore(
    "session",
    initialSession,
    validateSession
  );
  const sessionRef = useRefTrackingState(session);

  const refreshSession = useCallback(
    async ({ force, delayUpdateUntilRoute }) => {
      if (!sessionRef.current || force) {
        try {
          const sessionRes = await makeClientApi(NoOpRefreshSession)(
            "/api/auth/session",
            {}
          );
          const newSession = sessionRes.payload.session;

          if (newSession) {
            // Set a value we will use to check this client should silently
            // revalidate, using the value for revalidateAge returned by the server.
            newSession.expires = Date.now() + newSession.revalidateAge * 1000;
            if (delayUpdateUntilRoute) {
              setDelayedUpdateUntilRoute({
                session: newSession,
                route: new URL(delayUpdateUntilRoute, "https://autum.com")
                  .pathname,
              });
            } else {
              setSession(newSession);
            }
          }
        } catch (err) {
          // Assume failure
          setSession(null);
        }
      }
    },
    [sessionRef, setSession]
  );

  useEffect(() => {
    const loadSession = async () => {
      await refreshSession({ force: false });
    };

    loadSession();

    // We really only want this to run on the very first mount.
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const router = useRouter();

  useEffect(() => {
    if (
      delayedUpdateUntilRoute &&
      delayedUpdateUntilRoute.route === router.route
    ) {
      setSession(delayedUpdateUntilRoute.session);
      setDelayedUpdateUntilRoute(null);
    }
  }, [delayedUpdateUntilRoute, router.route]);

  const computedSession =
    delayedUpdateUntilRoute && delayedUpdateUntilRoute.route === router.route
      ? delayedUpdateUntilRoute.session
      : session;

  return (
    <authContext.Provider
      value={{
        user: computedSession ? computedSession.user : null,
        refreshSession,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
