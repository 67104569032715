import { useState, useRef, useEffect } from "react";

export const useRefTrackingState = (stateToTrack) => {
  const stateRef = useRef(stateToTrack);

  useEffect(() => {
    stateRef.current = stateToTrack;
  }, [stateToTrack]);

  return stateRef;
};

const useRefState = (initialState) => {
  const [state, setState] = useState(initialState);
  const stateRef = useRefTrackingState(state);

  return [state, setState, stateRef];
};

export default useRefState;
