import { useState, useEffect, useMemo } from "react";

export enum StorageType {
  SESSION,
  LOCAL,
}

export type ValidationFn = (item: any) => boolean;

const noopStorageApi = { getItem: () => undefined, setItem: () => {} };

const getStorageObject = (type: StorageType) => {
  if (typeof window === "undefined") {
    return noopStorageApi;
  }

  try {
    switch (type) {
      case StorageType.LOCAL:
        return window.localStorage;
      case StorageType.SESSION:
        return window.sessionStorage;
    }
  } catch (err) {
    // Allow security exceptions on accessing storage
    return noopStorageApi;
  }
};

const useStorage = (
  type: StorageType,
  key: string,
  initialValue: any,
  validationFn?: ValidationFn
) => {
  const storage = useMemo(() => getStorageObject(type), [type]);

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = storage.getItem(key);
      if (item && item !== "undefined") {
        const parsedItem = JSON.parse(item);
        if (!validationFn || validationFn(parsedItem)) {
          return parsedItem;
        }
      }
      return initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  useEffect(() => {
    storage?.setItem(key, JSON.stringify(storedValue));
  }, [key, storage, storedValue]);

  return [storedValue, setStoredValue];
};

export default useStorage;
