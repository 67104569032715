import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import useTheme from "../../hooks/use-theme";

type AnimatedSpinnerProps = {
  $size?: number;
  $borderWidth?: number;
  $borderColor?: string;
};
const AnimatedSpinner = styled(animated.div)<AnimatedSpinnerProps>`
  border: ${(props) => props.$borderWidth}px solid
    ${(props) => props.$borderColor};
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;

  margin: 0 auto;

  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const LoadingHeight = styled.div`
  height: 320px;
`;

type LoadingProps = {
  size?: number;
  borderWidth?: number;
  borderColor?: string;
  className?: string;
  children?: any;
};
const Loading = ({
  size = 50,
  borderWidth = 5,
  borderColor = null,
  className,
  children,
}: LoadingProps) => {
  const theme = useTheme();
  const [running, setRunning] = useState(true);
  useEffect(() => {
    if (!running) {
      setRunning(true);
    }
  }, [running]);

  const { radians } = useSpring({
    radians: 2 * Math.PI,
    from: { radians: 0 },
    config: { duration: 850 },
    reset: !running,
    onRest: () => setRunning(false),
  });
  return (
    <AnimatedSpinner
      {...{ children }}
      $size={size}
      $borderWidth={borderWidth}
      $borderColor={borderColor || theme.Color.TransparentLight}
      className={className}
      style={{
        transform: radians.to((r) => `rotate(${r}rad)`),
      }}
    />
  );
};

export default Loading;
Loading.Container = LoadingContainer;
Loading.Height = LoadingHeight;
