import React from "react";
import styled, { css } from "styled-components";

import flattenChildren from "@/util/flatten-children";

type ContainerProps = {
  $direction: string;
  $alignItems?: string;
  $justifyContent?: string;
  $wrapItems?: boolean;
  $spacing: number;
  $fullHeight?: boolean;
};
const Container = styled.div<ContainerProps>`
  ${(props) => css`
    /* This needs to be min-height rather than normal height because if this is used on around page containers on short screens, the content will be cut off by the header */
    ${props.$fullHeight && `min-height: 100%;`}

    display: flex;
    flex-direction: ${props.$direction};
    flex: 1;

    ${props.$justifyContent &&
    css`
      justify-content: ${props.$justifyContent};
    `}

    ${props.$alignItems &&
    css`
      align-items: ${props.$alignItems};
    `}

    ${props.$wrapItems
      ? css`
          /* One day, instead of negative margins... (not supported in Safari yet)
          row-gap: ${props.$spacing}px;
          column-gap: ${props.$spacing}px; */

          margin: ${props.$spacing / -2}px;
          flex-wrap: wrap;
        `
      : css`
          width: 100%;
        `}
  `}
`;

type ElementProps = {
  $spacing: number;
  $isRow: boolean;
  $flexItem: boolean;
  $wrapItems?: boolean;
  $fullHeight: boolean;
  $isSticky?: boolean;
};
const Element = styled.div<ElementProps>`
  ${(props) => css`
    ${props.$wrapItems
      ? css`
          margin: ${props.$spacing / 2}px;
        `
      : css`
          margin: 0 ${props.$isRow ? props.$spacing : 0}px
            ${!props.$isRow ? props.$spacing : 0}px 0;

          &:last-of-type {
            margin-right: 0;
            margin-bottom: 0;
          }
        `}

    ${props.$flexItem && `flex: 1;`}
    ${props.$fullHeight && `height: 100%;`}

    ${props.$isSticky &&
    css`
      ${props.theme.BackgroundBlur()}
      position: sticky;
      top: ${props.theme.HeaderHeight - 1}px;
      z-index: 2;
    `}
  `}
`;

export enum StackSpacing {
  // None is used when you just need our alignment helpers or for media queries, where we do not want spacing on some breakpoints
  NONE,

  // XSmall is used between text elements that need minimal breathing room
  XSMALL,

  // Small is used between elements that just need minimal breathing room, i.e., a title, traits, empty text
  SMALL,

  // Medium is used to separate sub-sections, i.e., header description and header stats
  MEDIUM,

  // Large is used between main sections, i.e., 2 silos
  LARGE,
}

export const stackSpacingLookup: { [k in StackSpacing]: number } = {
  [StackSpacing.NONE]: 0,
  [StackSpacing.XSMALL]: 5,
  [StackSpacing.SMALL]: 10,
  [StackSpacing.MEDIUM]: 20,
  [StackSpacing.LARGE]: 40,
  [StackSpacing.NONE]: 0,
};

export enum StackDirection {
  ROW,
  ROW_REVERSE,
  COLUMN,
  COLUMN_REVERSE,
}

const stackDirectionLookup: { [k in StackDirection]: string } = {
  [StackDirection.ROW]: "row",
  [StackDirection.ROW_REVERSE]: "row-reverse",
  [StackDirection.COLUMN]: "column",
  [StackDirection.COLUMN_REVERSE]: "column-reverse",
};

export enum StackAlignment {
  CENTER,

  // TOP and BOTTOM should be used for verticalAlignment
  TOP,
  BOTTOM,

  // LEFT and RIGHT should be used for horizontalAlignment
  LEFT,
  RIGHT,

  SPACE_BETWEEN,
  SPACE_AROUND,
}

const stackAlignmentLookup: { [k in StackAlignment]: string } = {
  [StackAlignment.CENTER]: "center",
  [StackAlignment.TOP]: "flex-start",
  [StackAlignment.BOTTOM]: "flex-end",
  [StackAlignment.LEFT]: "flex-start",
  [StackAlignment.RIGHT]: "flex-end",
  [StackAlignment.SPACE_BETWEEN]: "space-between",
  [StackAlignment.SPACE_AROUND]: "space-around",
};

export enum StretchItems {
  FIRST,
  LAST,
  ALL,
}

export type StackChildrenProps = {
  $stickyStackElement?: boolean;
  $flex?: boolean;
};

type StackProps = {
  children: React.ReactNode;
  spacing?: StackSpacing;
  direction?: StackDirection;
  horizontalAlignment?: StackAlignment;
  verticalAlignment?: StackAlignment;
  wrapItems?: boolean;
  className?: string;

  // Used mainly to help vertically center a Stack element when it's inside of another flexed item. Otherwise, vertically centering should work by using only the verticalAlignment props
  fullHeight?: boolean;

  // Used mainly to stretch the height of loading, error and empty sections
  stretchItems?: StretchItems;
};
const Stack = ({
  children: initialChildren,
  spacing = StackSpacing.LARGE,
  direction = StackDirection.COLUMN,
  horizontalAlignment,
  verticalAlignment,
  wrapItems,
  className,
  stretchItems,
  fullHeight,
}: StackProps) => {
  let alignItems;
  let justifyContent;
  switch (direction) {
    case StackDirection.ROW:
    case StackDirection.ROW_REVERSE:
      alignItems = stackAlignmentLookup[verticalAlignment];
      justifyContent = stackAlignmentLookup[horizontalAlignment];
      break;
    default:
      justifyContent = stackAlignmentLookup[verticalAlignment];
      alignItems = stackAlignmentLookup[horizontalAlignment];
      break;
  }

  // flattenChildren allows us to pass in an array of ReactNodes, wrapped in a react fragment
  const children = flattenChildren(initialChildren);

  return (
    <Container
      $direction={stackDirectionLookup[direction]}
      $alignItems={alignItems}
      $justifyContent={justifyContent}
      $wrapItems={wrapItems}
      $spacing={stackSpacingLookup[spacing]}
      $fullHeight={fullHeight}
      className={className}
    >
      {React.Children.map(children, (child, i) => {
        if (child) {
          return (
            <Element
              $isSticky={(child as any)?.props?.$stickyStackElement}
              $spacing={stackSpacingLookup[spacing]}
              $isRow={direction === StackDirection.ROW}
              $wrapItems={wrapItems}
              $flexItem={
                (child as any)?.props?.$flex ||
                stretchItems === StretchItems.ALL ||
                (stretchItems === StretchItems.FIRST && i === 0) ||
                (stretchItems === StretchItems.LAST &&
                  i + 1 === React.Children.count(children))
              }
              $fullHeight={direction === StackDirection.ROW && fullHeight}
              key={i}
            >
              {child}
            </Element>
          );
        }
      })}
    </Container>
  );
};

export default Stack;
