import axios from "axios";
import Router from "next/router";

import { ClientApiFn } from "@/types/api";

import { generateLoginRedirect } from "@/util/auth/redirects";
import { RefreshSessionFn } from "@/util/auth/auth";

const axiosInstance = axios.create({
  timeout: 240000,
  headers: {
    Accept: "application/json",
  },
  withCredentials: true,
});

export const makeClientApi = (
  refreshSession: RefreshSessionFn
): ClientApiFn => async (endpoint, params) => {
  try {
    const { data } = await axiosInstance.request({
      ...params,
      url: endpoint,
    });
    const { payload, refreshSessionAuth, redirect } = data;

    if (refreshSessionAuth) {
      await refreshSession({
        force: true,
        delayUpdateUntilRoute: redirect.destination,
      });
    }

    if (redirect) {
      if (redirect.replace) {
        Router.replace(redirect.destination);
      } else {
        Router.push(redirect.destination);
      }
    }

    return { payload, isChangingRoute: !!redirect };
  } catch (err) {
    if (err.response) {
      const { message, inputKey } = err.response.data;
      const status = err.response.status;
      if (status === 401) {
        // On unauthorized, we know the user's session has become invalid. Let's refreshSessionAuth.
        const redirect = generateLoginRedirect(Router);

        await refreshSession({
          force: true,
          delayUpdateUntilRoute: redirect,
        });

        Router.push(redirect);
      }
      const wrappedError = new Error(message) as any; // TODO:
      wrappedError.inputKey = inputKey;
      wrappedError.status = status;
      throw wrappedError;
    } else {
      throw err;
    }
  }
};
